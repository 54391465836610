import { useCallback, useContext, useState } from "react";
import * as React from "react";

import { SortableTreeWithoutDndContext } from "@nosferatu500/react-sortable-tree";
import FontAwesome from "../../utilities/FontAwesome";
import CleverSiteTreeTheme from './CleverSiteTreeTheme';

import styles from "./styles/tree.module.scss";
import classNames from "classnames";

import {observer} from "mobx-react";
import {toJS, get} from "mobx";
import FlatIcon from "../../utilities/FlatIcon";
import Button from "../../utilities/Button";
import {convertTreeToFlat} from "../../../utils/DataUtilities";
import {StoreContext} from "../../../stores/StoreLoader";
import {
    isIconLink, isLink, isModalLink,
    isSecondLevelListHeading, isTopLevel,
    isTopLevelIconHeading,
    isTopLevelListHeading
} from "../../modals/AdminModal/utilities";
import {conditionType} from "../../modals/AdminModal/AdminModalTypes";
import {fetchState} from "../../../stores/OrganizationStore";
import WatsonApi from "../../../backends/WatsonApi";
import NotificationManager from "../../notifications/NotificationManager";
import ClickableLink from "../../utilities/ClickableLink";

const treeButtonClassName = classNames({
    [styles.treeButton]: true,
});
const treeNodeClassName = classNames({
    [styles.treeNode]: true,
});

const SubtitleTextOptions = {
    "1_36": "(List with Headings)",
    "2_36": "(Icons + Description)",
    "2_40": "(Traditional Link)",
    "1_40": "(Heading)",
    "3_44": "(Link Under Heading)",
    "3_36": "(Traditional Link)",
    "3_40": "(Traditional Link)",
    "4_36": "(Modal Trigger)",
    "4_40": "(Modal Trigger)",
    "4_44": "(Modal Trigger)",
};

export const canDropNode = node => {
    return (isTopLevel(node.node) && !node.nextParent) || // if at top level, can move to top level
        ( // if heading, parent must be type 1 and have 36char length
            isSecondLevelListHeading(node.node) &&
            node.nextParent &&
            isTopLevelListHeading(node.nextParent)
        ) ||
        ( // allowed if top level, under a secondary heading, or under an icon top heading
            isLink(node.node) &&
            (
                !node.nextParent ||
                isSecondLevelListHeading(node.nextParent) ||
                isTopLevelIconHeading(node.nextParent)
            )
        );
};

export function getUpdatedItemPathsFromMove(previousFlatState: Navigation[], nextFlatState: Navigation[]) : Navigation[] {
    const updatedItems: Navigation[] = [];

    // find all items whose paths have changed and therefore need to be updated
    const nextById = nextFlatState.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
    }, {});
    previousFlatState.forEach(n => {
        let nextItem = nextById[n.id];
        if (nextItem.path !== n.path) {
            updatedItems.push(nextItem);
        }
    });

    return updatedItems;
}

export function numExpandedNodes(tree) {
    let n = 0;
    tree.forEach(t => {
        n++;
        if (t.expanded) {
            t.children.forEach(c => {
                n += numExpandedNodes([c])
            });
        }
    })
    return n;
}

export function mergeNavigationLists(currentList, newList) {
    const merged = new Map();
    currentList.forEach(c => {
        merged.set(c.id, c);
    });
    newList.forEach(n => {
        merged.set(n.id, {
            ...n,
            expanded: merged.get(n.id).expanded,
        });
    });
    return Array.from(merged.values())
        .sort((a, b) => a.path < b.path ? -1 : 1);
}

function applyValueToChildren(allNodes: Navigation[], parentNode: Navigation, alteration: Partial<Navigation>): Navigation[] {
    return allNodes
        .filter(item => item.path.slice(0, parentNode.path.length) === parentNode.path)
        .map(item => {
            return {...item, ...alteration};
        });
}

const Tree = observer((props: {
    toggleModal: (condition: conditionType, node: Navigation) => void,
    isTouch?: boolean,
}) => {
    const {organizationStore} = useContext(StoreContext);
    const [height, setHeight] = useState(numExpandedNodes(organizationStore.navigationTree) * 60 + 20 );

    const toggleEnabled = useCallback(async node => {
        try {
            const client = await WatsonApi();
            const method = node.enabled ? "organizations_navigations_disable" : "organizations_navigations_enable";
            const response = await client.apis.organizations[method]({
                organization_pk: organizationStore.organization.id,
                id: node.id,
            })
            const updated = JSON.parse(response.data);
            organizationStore.navigation = mergeNavigationLists(organizationStore.navigation, updated);
        } catch (e) {
            NotificationManager.error(JSON.parse(e.response.data).detail, "", 5000);
        }
    }, [organizationStore.navigation]);

    const handleMoveNode = useCallback(async obj => {
        // when an icon link is dragged to become a traditional link, or visa versa, we need to update its type
        if (isIconLink(obj.node) && (
            obj.nextParentNode === null ||
            isSecondLevelListHeading(obj.nextParentNode)
        )) {
            obj.node.type = 3;
        } else if (
            !isIconLink(obj.node) &&
            !isModalLink(obj.node) &&
            obj.nextParentNode &&
            isTopLevelIconHeading(obj.nextParentNode)) {
            obj.node.type = 2;
        }

        // remove the district link when editing
        const previousFlatState = toJS(organizationStore.navigation).filter(n => !n.is_district_link);
        const nextFlatState = convertTreeToFlat(obj.treeData, organizationStore.organization.id.replaceAll("-", ""));
        let updatedItems = getUpdatedItemPathsFromMove(previousFlatState, nextFlatState);

        if (obj.nextParentNode && !obj.nextParentNode.enabled) {
            // if the next parent is disabled, disable all children
            updatedItems = applyValueToChildren(updatedItems, obj.nextParentNode, {enabled: false});
        }

        // no need to hit the API with an empty list
        if (updatedItems.length === 0) return;

        /*
        this removes having to wait for the API to update the state. It's a bit of a trade-off, because this introduces the possibility for
        errors if the user ends another drag before the API has time to respond. Also add back the district link that we've possibly
        removed if we're at a school.
         */
        organizationStore.navigation = [...toJS(organizationStore.navigation).filter(n => n.is_district_link), ...nextFlatState];

        try {
            const res = await organizationStore.fetchClient("organizations", "organizations_navigations_bulk_update", {
                organization_pk: organizationStore.organization.id,
                data: updatedItems,
            }, {
                requestId: "navTreeDrop",
            });
            const updated = JSON.parse(res.data);
            organizationStore.navigation = mergeNavigationLists(organizationStore.navigation, updated);
        } catch (e) {
            console.debug('An error occurred: ', e);
            // reset to the previous state if something went wrong
            organizationStore.navigation = previousFlatState;
        }

    }, []);

    const deleteNavigation = async (node: Navigation) => {
        const includeAllChildren = organizationStore.navigation.filter(item => {
            return item.path.slice(0, node.path.length) === node.path;
        }).map(item => item.id);
        // use something like ^^ for getting all children and bulk deleting
        try {
            const client = await WatsonApi();
            const result = await client.apis.organizations.organizations_navigations_bulk_destroy({
                organization_pk: organizationStore.organization.id,
                data: includeAllChildren,
            })
            organizationStore.navigation = organizationStore._navigation.filter(item => !includeAllChildren.includes(item.id));
        } catch (error) {
            console.debug(error);
        }
    };

    const getNodeProps = useCallback((rowInfo) => {
        let subtitle =
            SubtitleTextOptions[
                `${rowInfo.node.type}_${rowInfo.node.path.length}`];
        let nodeProps = {
            className: treeNodeClassName as string,
            buttons: null as React.ReactNode,
            title: null as React.ReactNode,
            style: null as any | undefined,
        };

        const fs = get<any>(organizationStore.fetchStates, rowInfo.node.id)?.value;
        const isLoading = fs === fetchState["PENDING SCHEMA"] || fs === fetchState["PENDING RESPONSE"];

        nodeProps.buttons = <>
            {(isTopLevelListHeading(rowInfo.node) ||
            isTopLevelIconHeading(rowInfo.node) ||
            isSecondLevelListHeading(rowInfo.node)) &&
            <Button
                key="add"
                className={treeButtonClassName}
                onClick={() => props.toggleModal(isTopLevelListHeading(rowInfo.node) ?
                    conditionType.ADD_HEADING : conditionType.ADD_SUBLINK, rowInfo.node)}
                aria-label={isTopLevelListHeading(rowInfo.node) ? "Add Heading" : "Add Sublink"}
            >
                <FontAwesome ariaHidden={false} prefix={'fas'} name={"fa-plus"}/>
            </Button>}
            {rowInfo.node.enabled && <Button
                key="edit"
                className={treeButtonClassName}
                onClick={() => props.toggleModal(conditionType.EDITING, rowInfo.node)}
                aria-label={"Edit Link"}
            >
                <FlatIcon ariaHidden={false} name={"flaticon-draw"}/>
            </Button>}
            <Button
                key="enabled"
                className={treeButtonClassName}
                onClick={() => toggleEnabled(rowInfo.node)}
                aria-label={rowInfo.node.enabled ? "Disable" : "Enable"}
            >
                {isLoading ? <FontAwesome prefix={'fas'} name={'fa-spinner'} spin /> :
                <FlatIcon ariaHidden={false} name={rowInfo.node.enabled ? "flaticon-hidden" : "flaticon-eye"}/>}
            </Button>
            <Button
                key="delete"
                className={treeButtonClassName}
                onClick={() => {
                    if (confirm("Are you sure you want to do that?")) {
                        deleteNavigation(rowInfo.node);
                    }
                }}
                aria-label={"Delete"}
            >
                <FlatIcon ariaHidden={false} name={"flaticon-remove"}/>
            </Button>
        </>

        const nodeTitleClassName = classNames({
            [styles.nodeTitle]: true,
            [styles.nodeDisabled]: !rowInfo.node.enabled,
        })

        const nodeSubtitleClassName = classNames({
            [styles.nodeSubtitle]: true,
            [styles.nodeDisabled]: !rowInfo.node.enabled,
        })

        const nodeIconClassName = classNames({
            [styles.nodeIcon]: true,
            [styles.nodeIconDisabled]: !rowInfo.node.enabled,
        })

        if (rowInfo.node.url && rowInfo.node.type !== 4) {
            nodeProps.title = <>
                {rowInfo.node.icon && <FontAwesome key="nodeIcon" className={nodeIconClassName} ariaHidden={true} name={rowInfo.node.icon}/>}
                <ClickableLink target={"_blank"} href={rowInfo.node.url} className={nodeTitleClassName}>
                    {rowInfo.node.title}
                </ClickableLink>
                <span className={nodeSubtitleClassName}>
                        {subtitle}
                        </span>
                {!rowInfo.node.enabled && <FontAwesome  key="nodeHiddenIcon" name={'fa-eye-slash'} prefix={'far'} className={styles.nodeHiddenIcon} />}
            </>
        } else {
            nodeProps.title = <>
                {rowInfo.node.icon && <FontAwesome key="nodeIcon" className={nodeIconClassName} ariaHidden={true} name={rowInfo.node.icon}/>}
                <p className={nodeTitleClassName}>
                    {rowInfo.node.title}
                </p>
                <span className={nodeSubtitleClassName}>
                        {subtitle}
                        </span>
                {!rowInfo.node.enabled && <FontAwesome  key="nodeHiddenIcon" name={'fa-eye-slash'} prefix={'far'} className={styles.nodeHiddenIcon} />}
            </>
        }

        return nodeProps;
    }, [organizationStore.fetchStates.size]);

    const fs = get(organizationStore.fetchStates, "navTreeDrop")?.value;

    const treeContainer = classNames({
        [styles.container]: true,
        [styles.containerLoading]: fs === fetchState["PENDING RESPONSE"] || fs === fetchState["PENDING SCHEMA"],
    })

    return (
        <SortableTreeWithoutDndContext
            style={{height}}
            treeData={organizationStore.navigationTree.filter(n => !n.is_district_link)}
            onChange={() => {
            }}
            onVisibilityToggle={({treeData, node, expanded}) => {
                organizationStore.navigation = organizationStore.navigation.map(n => {
                    if (n.id === node.id) {
                        n.expanded = expanded;
                    }
                    return n;
                })
                setHeight(numExpandedNodes(treeData) * 60 + 20);
            }}
            onMoveNode={handleMoveNode}
            theme={CleverSiteTreeTheme}
            rowHeight={60}
            scaffoldBlockPxWidth={30}
            generateNodeProps={getNodeProps}
            canDrop={canDropNode}
            getNodeKey={({node}) => node.id}
            maxDepth={3}
            canNodeHaveChildren={node => isTopLevelIconHeading(node) || isTopLevelListHeading(node) || isSecondLevelListHeading(node)}
            className={treeContainer}
        />
    );
})

export default Tree
